import React from 'react'
import Link from 'gatsby-link'
import { isMobile } from '../utils/helpers.js'
import { MDBMask, MDBRow, MDBCol, MDBView, MDBContainer, MDBBtn } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'
import Tilt from 'react-tilt'

class Hero extends React.Component {
  handleClick(value) {
    typeof window !== 'undefined' && document.querySelector(value).scrollIntoView({
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <section className={this.props.class}>
          <MDBView>
            <MDBMask className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center align-items-center gradient' }
            >
              <MDBContainer>
                <MDBRow>
                  {this.props.type === 'home' || this.props.type === '404' ? (
                    <>
                      <MDBCol lg="6" className="my-auto white-text text-lg-left text-center pb-5 pb-lg-0" > 
                        <h1 className="font-alt font-w-700 text-white title-xs-extra-large title-extra-large-1 mt-sm-5 mb-xl-5"> Transform the way you work </h1> 
                        <h2 className="font-alt font-w-400 mb-5 title-xs-small title-small letter-spacing-1"> Globalize your business and reinvent culture, ethics, and processes. </h2>
                        {this.props.ctaslug && (

                            <MDBBtn className="nav-link btn btn-hero-color mr-0 mt-xl-2 btn-w-245 hero-btn1"                            
                              onClick={() => this.handleClick(this.props.ctaslug)}
                            >
                              <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                              {this.props.ctatext ? this.props.ctatext : 'Explore'}
                            </MDBBtn>
                          )}
                          {this.props.ctaslug2 && ( 
                            <>
                            {this.props.ctaslug2.substring(0,4) === 'http' ? 
                            <a href={this.props.ctaslug2} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2">
                              <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                              {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                            </a>
                            :
                            <Link to={this.props.ctaslug2} className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2">
                              <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                              {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                            </Link>
                            }
                            </>
                          )}

                      </MDBCol>
                    </>
                  ) : this.props.type === 'news' ? (
                    <>
                      <h1
                        className="font-alt font-w-700 text-left text-md-left text-white title-xs-medium title-large-3 mb-xl-2 mx-3"
                        dangerouslySetInnerHTML={{ __html: this.props.title }}
                      />
                      {this.props.subtitle != '' &&
                        this.props.subtitle != null && (
                          <h2
                            className="text-white font-w-700 font-alt mb-4 title-xs-medium mx-3 letter-spacing-1"
                            dangerouslySetInnerHTML={{
                              __html: this.props.subtitle,
                            }}
                          />
                        )}

                      {this.props.ctaslug != '' && this.props.ctaslug != null && (
                        <Link to={this.props.ctaslug} className="nav-link btn btn-mdb-color mr-0 mt-xl-3 ml-3" >
                          <FontAwesomeIcon icon={faChevronRight} />
                          {this.props.ctatext != '' && this.props.ctatext != null
                            ? this.props.ctatext
                            : 'Read'}
                        </Link>
                      )}
                    </>
                  ) : (
                    <div className="white-text text-center col-md-12 mt-xl-2">
                      <h1
                        className="font-alt font-w-700 text-yellow title-xs-extra-large title-large-3 mt-sm-5 mb-xl-4"
                        dangerouslySetInnerHTML={{ __html: this.props.title }}
                      />
                      {this.props.subtitle != '' &&
                        this.props.subtitle != null && (
                          <h2
                            className="text-white font-alt font-w-700 mb-4 title-xs-medium letter-spacing-1"
                            dangerouslySetInnerHTML={{
                              __html: this.props.subtitle,
                            }}
                          />
                        )}
                    {this.props.ctaslug != '' && this.props.ctaslug != null && (
                      <Link to={this.props.ctaslug} className="nav-link btn btn-mdb-color mr-0 mt-xl-3" >
                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                        {this.props.ctatext != '' && this.props.ctatext != null
                          ? this.props.ctatext
                          : 'Read'}
                      </Link>
                    )}
                    </div>
                  )}

                  {!isMobile() && (
                      <MDBCol lg="6" className="mt-5 mt-lg-0 pl-lg-5">
                        <div className="software-img d-none d-lg-block">
                          <Tilt
                            className="Tilt"
                            options={{ max: 25 }}
                            // style={{ height: 500, width: 500 }}
                          >
                            <img src={this.props.foreimage.images.fallback.src} loading="eager" fetchpriority="high" alt={this.props.imageAltText} />
                          </Tilt>
                        </div>
                      </MDBCol>
                    )}
                </MDBRow>
              </MDBContainer>
              <div id="scrollto">&nbsp;</div>
            </MDBMask>
          </MDBView>
      </section>
    )
  }
}
export default Hero