import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroDynamic'
import NewsSection from '../components/homeNews'
import OverlayCard from '../components/overlayCard'
import AltSection from '../components/columnAlternatingStyled'

const Index = ({ data, location }) => {

  const post = data.markdownRemark

  return (
    <Layout>
      <div id="intro-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="home-110"
          foreimage={post.frontmatter.image.childImageSharp.gatsbyImageData}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Why UNICOM Digital?"
          ctaslug2="/contact/"
          ctatext2="Schedule a consultation"          
          type="home"
        />
      </div>
      
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <OverlayCard
            image={post.frontmatter.cardimage.childImageSharp.gatsbyImageData}
            cardtagtext={post.frontmatter.cardtagtext}
            cardtitle={post.frontmatter.cardtitle}
            cardtext={post.frontmatter.cardtext}
            body={post.html}
          />
        </MDBContainer>

        {post.frontmatter.section &&
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              <div className="about-overview">
                <h2 className="font-alt font-w-700 text-center letter-spacing-1 title-xs-medium title-large pb-7">
                  Fully committed to solving your digital transformation challenges 
                </h2>
                {post.frontmatter.section.map((sections, index) => {
                  return (
                   <AltSection
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : '' }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      imageAltText={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={sections.btnlink}
                      colour={sections.btncolour}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }

        <NewsSection />
      </main>
    </Layout>
  )
}
export default Index 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      frontmatter {
        meta {
          title
          description 
        }
        cardtitle
        title
        subtitle
        bodytitle
        cardtagtext
        cardtext
        cardimage {
          childImageSharp {
            gatsbyImageData(width: 100, quality: 90) 
          }
        }
        alttext
        image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 540, quality: 95) 
          }
        }
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
      }
    }
  }
`
